<template>
  <div class="term-privacy-page w-100">
    <SectionDonate />
    <SectionPhone class="mt-5 pb-3 pt-3 pb-md-5 pb-xxl-5 bg-white" />
  </div>
</template>

<script>
import SectionDonate from './SectionsAbout/SectionDonate'
import SectionPhone from "@/views/Landing/SectionPhone";

export default {
  name: "Donate",
  title: "donate",
  components: {
    SectionPhone,
    SectionDonate
  },
};
</script>