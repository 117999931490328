<template>
  <div class="section-title">
      <h4 v-if="title">{{ title }}</h4>
      <slot></slot>
  </div>
</template>

<script>
export default {
    name: "SectionAboutTitle",
    props: {
        title: {
            type: String,
            default: ''
        }
    }
}
</script>
