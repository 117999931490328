<template>
  <div class="section-content section-pickup pt-4 pb-5 w-100 container">
    <b-container class="container-xlg m-auto pl-xxl-0 pr-xxl-0 about-content">
      <div class="top-event d-flex flex-column flex-md-column">
        <esports-title
          title="DONATE"
          class="pb-2 pt-2 mb-2 mt-2 mt-xxl-4 pb-xxl-5 article-page"
          sub_title="ドネトについて"
        />
      </div>

      <SectionAboutTitle class="mt-0" title="投票と抽選について">
            <p>
                  <span class="sub-title">投票方法</span><br />
                  大会情報から投票したい大会開くと、「出場予定選手」が表示されますので応援したい選手の横に表示されている「応援する」をクリックで投票は完了します。
            </p>
            <p>
                  <span class="sub-title">投票した選手の確認方法</span><br />
                  マイページから投票履歴でご確認いただけます。
            </p>
            <p>
                  <span class="sub-title">投票券の取得方法</span><br />
                  （1）大会情報・EVENTから大会情報をリツイートして1票<br />
                  （2）票取得・TICKETの各種広告利用<br />
                  （3）メニュー・マイページから友達紹介するとそれぞれに10票<br />
                  （4）ログインボーナスとして1票
            </p>
            <p>
                  <span class="sub-title">抽選方法</span><br />
                  投票数に応じて抽選権を付与します。抽選権に対して公平に抽選を行います。投票数に対しての抽選となるため、投票数が多いほど、当選確率は上がります。なお、抽選はコンピューターで無作為に行います。この過程において、人間の恣意が途中で介入することはありません。なお登録選手及び大会参加選手は投票自体は可能ですが抽選は対象外となります。
            </p>
            <p>
                  <span class="sub-title">当選結果の連絡</span><br />
                  大会の優勝者が決定した日から7営業日後までにメールにて当選通知と同時にドネトの付与を行います。過去の当選はドネト履歴からもご確認いただけます。
            </p>
      </SectionAboutTitle>
      <SectionAboutTitle class="mt-3 mt-md-5" title="ドネトとAmazonギフトについて">
            <p>
                  <span class="sub-title">ドネトの付与</span><br />
                  （1）大会優勝者を予想し的中した人の中から抽選でドネトを付与します。付与するドネトは大会毎に異なるため、詳しくは大会情報をご確認下さい。<br />
                  ※登録選手と大会出場選手は抽選対象から除外<br />
                  （2）投票券を利用して「応援する」を10回実行すると1ドネトたまります。つまり日々のログインや大会情報のリツイート、広告を利用をして投票券をためて、応援するだけでもドネトがたまります。
            </p>
            <p>
                  <span class="sub-title">ドネトの確認方法</span><br />
                  過去の当選はドネト履歴からご確認いただけます
            </p>
            <p>
                  <span class="sub-title">ドネトの交換方法</span><br />
                  マイページの「ドネト交換」からAmazonギフトへ交換可能です。
            </p>
            <p>
                  <span class="sub-title">Amazonギフトの受け取り方</span><br />
                  マイページの「ドネト交換」で申請して頂いてから1ヶ月程度で、登録メールアドレスにギフト券番号をお送りします。届いたギフト券番号をAmazonアカウントに登録すると、Amazonギフト券残高に追加されます。
            </p>
      </SectionAboutTitle>
      <SectionAboutTitle class="mt-3 mt-md-5" title="掲載されている情報について">
            <p>
                  当サービスにて掲載されている情報についてApple Incは一切関与しておりません。提供された情報を利用して生じた一切の渉外についてApple Incは責任を負いません。
            </p>
      </SectionAboutTitle>
    </b-container>
  </div>
</template>

<script>
import SectionAboutTitle from './SectionAboutTitle';

export default {
    name: "SectionDonate",
    components: { SectionAboutTitle },
    data() {
          return {
                URL_FRONT_END:  process.env.VUE_APP_FRONT_END
           }
    }

}
</script>